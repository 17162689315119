import React, { useState, useEffect } from "react"
import useInterval from "./utils/useInterval"
const Progress = ({ delay }) => {
  const [percentage, setPercentage] = useState(0)

  const step = (100 / (delay - 300)) * 100
  useInterval(() => {
    if (percentage === 100) return
    setPercentage(percentage + step)
  }, 100)
  return (
    <div className="progress-container">
      <ProgressBar percentage={percentage} />
    </div>
  )
}

const ProgressBar = props => {
  return (
    <div className="progress-bar">
      <Filler percentage={props.percentage} />
    </div>
  )
}

const Filler = props => {
  return <div className="filler" style={{ width: `${props.percentage}%` }} />
}

export default Progress
