import React, { useEffect, useState } from "react"
import { unisender, API_KEY, hubspot, emailEndpoint } from "../api";

const modal = ({ closeDialog }) => {
  const [email, setEmail] = useState('')
  const [fake, setFake] = useState({
    firstName: '',
    lastName: '',
  })
  const [valid, setValid] = useState(false)
  const [submitted, setsubmitted] = useState(false)
  const [error, seterror] = useState(false)
  const handleChange = e => {
    setEmail(e.target.value)
    validate(e.target.value)
  }

  const validate = (val) => {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    setValid(re.test(String(val).toLowerCase()));
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (fake.firstName !== '' || fake.lastName !== '') {
      setsubmitted(true)
      seterror(false)
      return;
    }
    const response = await unisender
      .get(`subscribe?format=json&api_key=${API_KEY}&list_ids=19071961&fields[email]=${email}&double_optin=3`)
    if (response.statusText === 'OK') {
      await hubspot.post(`/${emailEndpoint}`, {
        fields: [{
          name: 'email',
          value: email,
        }]
      })
      setsubmitted(true)
      seterror(false)
    } else if (!response.statusText) {
      setsubmitted(false)
      seterror(true)
    }
  }
  useEffect(() => {
    document.body.style.overflow = "hidden"
    return () => (document.body.style.overflow = "unset")
  }, [])

  const handleFakeChange = e => {
    setFake({ ...fake, [e.target.name]: e.target.value })
  }
  return (
    <div className="modal">
      <div className="modal-overlay" onClick={closeDialog}></div>
      <div className="modal-body">
        {submitted ?
          <>
            <h1>Ваш запрос принят!</h1>
            <p>Каталог отправлен на Вашу почту!</p>
          </>
          :
          <>
            <h1>
              Введите Ваш email.</h1>
            <p>
              Каталог будет автоматически отправлен на Вашу почту.
          </p>
          </>}
        {submitted ? null :
          <form onSubmit={handleSubmit}>
            <fieldset className='important'>
              <label htmlFor="firstNameModal">Имя</label>
              <input value={fake.firstName} autoComplete='your_name' onChange={e => handleFakeChange(e)} name="firstName" id="firstNameModal" type="text"></input>
            </fieldset>
            <fieldset className='important'>
              <label htmlFor="lastNameModal">Фамилия</label>
              <input value={fake.lastName} autoComplete='your_name' onChange={e => handleFakeChange(e)} name="lastName" id="lastNameModal" type="text"></input>
            </fieldset>
            <label htmlFor="MERGE0">E-mail</label>
            <input
              required
              type="email"
              autoCapitalize="off"
              autoCorrect="off"
              name="MERGE0"
              id="MERGE0"
              value={email}
              onChange={e => handleChange(e)}></input>
            <button disabled={!valid} type="submit">Отправить</button>
          </form>
        }

      </div>
    </div>
  )
}

export default modal
