import React, { useState } from "react"
import { Slides } from "../data/Slides"
import ProgressBar from "../components/progress"
import useInterval from "../components/utils/useInterval"

const HeroSlider = () => {
  const [slide, setSlide] = useState(0)

  useInterval(() => {
    if (slide === Slides.hero.length - 1) {
      setSlide(0)
    }
    nextSlide()
  }, 5000)

  const handleDigitClick = i => {
    // setSlide(i)
  }
  const nextSlide = () => {
    if (slide === Slides.hero.length - 1) return
    setSlide(slide + 1)
  }
  const offset = `${-slide * 100}%`
  return (
    <>
      <div className="slider">
        <div
          className="monitor"
          style={{
            transform: `translateX(${offset})`,
          }}
        >
          {Slides.hero.map((slide, i) => (
            <React.Fragment key={i}>{slide}</React.Fragment>
          ))}
        </div>
      </div>
      <div className="slider-nav">
        {Slides.hero.map((_, i) => (
          <a
            className={i === slide ? "active" : ""}
            key={i}
            onClick={() => handleDigitClick(i)}
          >
            <span> 0{i + 1}</span>
            {i === slide && <ProgressBar delay={5000} />}
          </a>
        ))}
      </div>
    </>
  )
}

export default HeroSlider
