import React, { useState } from "react"
import Russia from "../assets/icons/russia.inline.svg"
import Designers from "../assets/icons/designers.inline.svg"
import Stock from "../assets/icons/stock.inline.svg"
import WhyUsImage from "../components/images/why-us"
import VisibilitySensor from "react-visibility-sensor"

const WhyUs = ({ viewport }) => {
  const [visible, setVisible] = useState(false)
  const onChangeVisibility = isVisible => {
    setVisible(isVisible)
  }
  return (
    <VisibilitySensor
      partialVisibility
      minTopValue="100"
      onChange={onChangeVisibility}
      active={!visible}
    >
      <section
        className={`why-us ${
          viewport === 'desktop' ? (visible ? "visible" : "invisible") : ""
          }`}
        id="why-us"
      >
        <div className="image">
          <WhyUsImage />
        </div>
        <div className="description">
          <h2>Почему выбрать нас?</h2>
          <p>
            Компания LedMebel больше 10 лет занимается разработкой и производством мебели, светильников и кашпо
          </p>
        </div>
        <div className="highlights">
          <div>
            <Designers />
            <h3>Лучшие дизайнеры</h3>
            <p>
              Наши дизайнеры, опираясь на новейшие тенденции, постоянно создают новые формы
            </p>
          </div>
          <div>
            <Russia />
            <h3>Сделано в России</h3>
            <p>Все комплектующие разработаны, изготовлены и установлены на территории РФ</p>
          </div>
          <div>
            <Stock />
            <h3>Всегда в наличии</h3>
            <p>
              Любая позиция из нашего каталога, всегда находится на складе и готова к отгрузке
            </p>
          </div>
        </div>
        <div className="shadow"></div>
      </section>
    </VisibilitySensor>
  )
}

export default WhyUs
