import axios from 'axios'

export const API_KEY = '6e1yhucaswqju9iseqot5emwsjq41jqst38qzago'
export const unisender = axios.create({
  baseURL: `https://api.unisender.com/ru/api/`,
});

const portalId = "6682497";
export const emailEndpoint = "9b549bd1-5e47-4c8e-8ac2-9a8644aa4de8";
export const fullFormEndpoint = "e5fad898-a7b2-43b7-a4ef-06a375522ff5";

export const hubspot = axios.create({
  baseURL: `https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/`,
  headers: { 'Content-Type': 'application/json' }
})
