import React, { useState, useEffect } from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../sections/Hero"
import OurProducts from "../sections/OurProducts"
import CubesBalls from "../sections/CubesBalls"
import Cachepot from "../sections/Cachepot"
import Furniture from "../sections/Furniture"
import Lamps from "../sections/Lamps"
import WhyUs from "../sections/WhyUs"
import Contact from "../sections/Contact"
import Modal from "../components/modal"
import ToTop from "../components/toTop"
export function debounce(func, wait = 20, immediate = true) {
  let timeout;
  return function () {
    const context = this;
    const args = arguments;
    const later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    const callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}

const IndexPage = () => {
  const [viewport, setViewport] = useState('desktop')
  const [modal, setModal] = useState(false)
  const [toTopVisible, setToTopVisible] = useState(false)
  useEffect(() => {
    window.addEventListener("resize", updateWindowDimensions)
    updateWindowDimensions()
    return () => {
      window.removeEventListener("resize", updateWindowDimensions)
    }
  }, [])

  useEffect(
    () => {
      const handleScroll = () => {
        if (typeof window !== 'undefined') {
          if (window.scrollY - window.innerHeight >= 0) {
            setToTopVisible(true)
          } else {
            setToTopVisible(false)
          }
        }
      };
      window.addEventListener("scroll", debounce(handleScroll));
      return () => window.removeEventListener("scroll", debounce(handleScroll));
    },
    [debounce] // If you remove this, things go 🍌🍌🍌
  );

  const updateWindowDimensions = () => {
    let currentViewport
    if (window.innerWidth < 768) {
      currentViewport = 'mobile'
    } else if (window.innerWidth < 1200 && window.innerWidth >= 768) {
      currentViewport = 'tablet'
    } else {
      currentViewport = 'desktop'
    }
    setViewport(currentViewport)

  }
  const showDialog = () => {
    setModal(true)
  }
  const closeDialog = () => {
    setModal(false)
  }

  return (
    <>
      <Layout>
        <SEO title="Световая мебель" />
        <Hero showDialog={showDialog} viewport={viewport} />
        <OurProducts viewport={viewport} />
        <CubesBalls viewport={viewport} />
        <Cachepot viewport={viewport} />
        <Furniture viewport={viewport} />
        <Lamps viewport={viewport} />
        <WhyUs viewport={viewport} />
        <Contact showDialog={showDialog} />
        <ToTop visible={toTopVisible} />
      </Layout>
      {modal && <Modal closeDialog={closeDialog} />}
    </>
  )
}

export default IndexPage
