import React, { useState, useEffect, useRef } from "react"
import CarouselLeft from "../assets/icons/carousel-left.inline.svg"
import CarouselRight from "../assets/icons/carousel-right.inline.svg"

const SmallCarousel = ({ items }) => {
  const [slide, setSlide] = useState(0)
  const prevSlide = () => {
    if (slide === 0) return
    setSlide(slide - 1)
  }

  const nextSlide = () => {
    if (slide === items.length - 1) return
    setSlide(slide + 1)
  }

  const ref = useRef(null)

  const onSwipeLeft = event => {
    event.stopPropagation()
    event.preventDefault()
    nextSlide()
  }

  const onSwipeRight = event => {
    event.stopPropagation()
    event.preventDefault()
    prevSlide()
  }

  useEffect(() => {
    const el = ref.current
    el.addEventListener("swipeleft", e => onSwipeLeft(e))
    el.addEventListener("swiperight", e => onSwipeRight(e))

    return () => {
      el.removeEventListener("swipeleft", e => onSwipeLeft(e))
      el.removeEventListener("swiperight", e => onSwipeRight(e))
    }
  }, [slide])

  const control = `${-slide * 100}%`

  return (
    <>
      <div
        ref={ref}
        className="monitor"
        style={{
          transform: `translateX(${control})`,
        }}
      >
        {items.map((item, i) => (
          <React.Fragment key={i}>{item}</React.Fragment>
        ))}
      </div>
      <div className="slider-controls">
        <a onClick={prevSlide} disabled={slide === 0}>
          <CarouselLeft />
        </a>
        <a onClick={nextSlide} disabled={slide === items.length - 1}>
          <CarouselRight />
        </a>
      </div>
    </>
  )
}

export default SmallCarousel
