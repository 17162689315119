import React from "react"
import HeroImage from "../components/images/hero-image"
import CubeOne from "../components/images/cubes-one"
import BallsOne from "../components/images/balls-one"
import BallsOneHover from "../components/images/balls-one-h"
import BallsTwo from "../components/images/balls-two"
import BallsTwoHover from "../components/images/balls-two-h"
import CachepotOne from "../components/images/cachepot-one"
import CachepotTwo from "../components/images/cachepot-two"
import CachepotThree from "../components/images/cachepot-three"
import FurnitureOne from "../components/images/furniture-one"
import FurnitureTwo from "../components/images/furniture-two"
import FurnitureThree from "../components/images/furniture-three"
import LampOne from "../components/images/lamp-one"
import LampTwo from "../components/images/lamp-two"
import LampThree from "../components/images/lamp-three"
import Resilent from "../assets/icons/resilient.inline.svg"
import Waterproof from "../assets/icons/waterproof.inline.svg"
import PowerSupply from "../assets/icons/power-supply.inline.svg"
import Stout from "../assets/icons/stout.inline.svg"
import RGB from "../assets/icons/rgb.inline.svg"
import Eco from "../assets/icons/eco.inline.svg"
import CachepotThreeHover from "../components/images/cachepot-three-h"
import CachepotOneHover from "../components/images/cachepot-one-h"
import CachepotTwoHover from "../components/images/cachepot-two-h"
import FurnitureOneHover from "../components/images/furniture-one-h"
import FurnitureThreeHover from "../components/images/furniture-three-h"
import FurnitureTwoHover from "../components/images/furniture-two-h"
import CubesOneHover from "../components/images/cubes-one-h"
import LampOneHover from "../components/images/lamp-one-h"
import LampTwoHover from "../components/images/lamp-two-h"
import LampThreeHover from "../components/images/lamp-three-h"
import HeroImageTwo from "../components/images/hero-image-two"
import HeroImageThree from "../components/images/hero-image-three"
import HeroImageFour from "../components/images/hero-image-four"

export const Slides = {
  hero: [
    <HeroImage />,
    <HeroImageTwo />,
    <HeroImageThree />,
    <HeroImageFour />,
  ],
  ourProducts: [
    <div>
      <Resilent />
      <h3>Стойкие</h3>
      <p>В любую погоду: от - 50º до +50º</p>
    </div>,
    <div>
      <Waterproof />
      <h3>IP 65 – IP 68</h3>
      <p>Выдерживают любой дождь</p>
    </div>,
    <div>
      <PowerSupply />
      <h3>12V и 220V </h3>
      <p>Работают от аккумулятора и от сети</p>
    </div>,
    <div>
      <Stout />
      <h3>Прочные</h3>
      <p>Сделаны из ударопрочного материала</p>
    </div>,
    <div>
      <RGB />
      <h3>RGB Mode</h3>
      <p>Поддерживают все RGB цвета</p>
    </div>,
    <div>
      <Eco />
      <h3>Экологичные</h3>
      <p>Произведены с заботой о природе</p>
    </div>,
  ],
  cubesBalls: [
    <div className="hoverable">
      <BallsOne className="initial" />
      <BallsOneHover className="hover" />
      <p>Minge Шар</p>
    </div>,
    <div className="hoverable">
      <CubeOne className="initial" />
      <CubesOneHover className="hover" />
      <p>Piazza Куб</p>
    </div>,
    <div className="hoverable">
      <BallsTwo className="initial" />
      <BallsTwoHover className="hover" />
      <p>Minge Шар</p>
    </div>,
  ],
  cachepot: [
    <div className="hoverable">
      <CachepotOne className="initial" />
      <CachepotOneHover className="hover" />
      <p>Linea Кашпо</p>
    </div>,
    <div className="hoverable">
      <CachepotTwo className="initial" />
      <CachepotTwoHover className="hover" />
      <p>Quadrum Кашпо</p>
    </div>,
    <div className="hoverable">
      <CachepotThree className="initial" />
      <CachepotThreeHover className="hover" />
      <p>High Кашпо</p>
    </div>,
  ],
  furniture: [
    <div className="hoverable">
      <FurnitureOne className="initial" />
      <FurnitureOneHover className="hover" />
      <p>Soho Cтул</p>
    </div>,
    <div className="hoverable">
      <FurnitureTwo className="initial" />
      <FurnitureTwoHover className="hover" />
      <p>Bowery Кресло</p>
    </div>,
    <div className="hoverable">
      <FurnitureThree className="initial" />
      <FurnitureThreeHover className="hover" />
      <p>Сhelsea Пуф</p>
    </div>,
  ],
  lamps: [
    <div className="hoverable">
      <LampOne className="initial" />
      <LampOneHover className="hover" />
      <p>Minge Светильник</p>
    </div>,
    <div className="hoverable">
      <LampTwo className="initial" />
      <LampTwoHover className="hover" />
      <p>Lamo Светильник</p>
    </div>,
    <div className="hoverable">
      <LampThree className="initial" />
      <LampThreeHover className="hover" />
      <p>Egg Светильник</p>
    </div>,
  ],
}
