import React, { useState, useRef, useEffect } from "react"
import { Link } from "gatsby"
import Arrow from "../assets/icons/arrow-right.inline.svg"
import VisibilitySensor from "react-visibility-sensor"

import CarouselLeft from "../assets/icons/carousel-left.inline.svg"
import CarouselRight from "../assets/icons/carousel-right.inline.svg"
import { Slides } from "../data/Slides"

const OurProducts = ({ viewport }) => {
  const [currentSlide, setCurrentSlide] = useState(0)
  const [visible, setVisible] = useState(false)

  const prevSlide = () => {
    if (currentSlide === 0) return
    setCurrentSlide(currentSlide - 1)
    console.log("prev")
  }
  const nextSlide = () => {
    if (currentSlide === Slides.ourProducts.length - 1) return
    setCurrentSlide(currentSlide + 1)
  }
  const onChangeVisibility = isVisible => {
    setVisible(isVisible)
  }

  const ref = useRef(null)

  const onSwipeLeft = event => {
    event.stopPropagation()
    event.preventDefault()
    nextSlide()
  }

  const onSwipeRight = event => {
    event.stopPropagation()
    event.preventDefault()
    prevSlide()
  }

  useEffect(() => {
    const el = ref.current
    if (viewport === 'mobile') {
      el.addEventListener("swipeleft", e => onSwipeLeft(e))
      el.addEventListener("swiperight", e => onSwipeRight(e))
    }
    return () => {
      if (viewport === 'mobile') {
        el.removeEventListener("swipeleft", e => onSwipeLeft(e))
        el.removeEventListener("swiperight", e => onSwipeRight(e))
      }
    }
  }, [viewport, currentSlide])

  return (
    <VisibilitySensor
      partialVisibility
      minTopValue="100"
      onChange={onChangeVisibility}
      active={!visible}
    >
      <section
        className={`our-products ${
          viewport === 'desktop' ? (visible ? "visible" : "invisible") : ""
          }`}
        id="our-products"
      >
        <div className="shadow"></div>
        <div className="description">
          <h1>Наши продукты</h1>
          <p>
            Уникальный стиль и практичность
            <br />
            использования
          </p>
          <ul className="list">
            <li>
              <Link className="arrow-link" to="/#cubes-balls">
                Кубы и шары
                <Arrow />
              </Link>
            </li>
            <li>
              <Link className="arrow-link" to="/#cachepot">
                Кашпо
                <Arrow />
              </Link>
            </li>
            <li>
              <Link className="arrow-link" to="/#furniture">
                Мебель для гостиниц
                <Arrow />
              </Link>
            </li>
            <li>
              <Link className="arrow-link" to="/#lamps">
                Светильники
                <Arrow />
              </Link>
            </li>
          </ul>
        </div>
        <div className={`icons ${viewport === 'mobile' ? "carousel" : ""}`} ref={ref}>
          <div
            className={`icons-group ${viewport === 'mobile' ? "monitor" : ""}`}
            style={{
              transform: `translateX(${
                viewport === 'mobile' ? `${-currentSlide * 100}%` : 0
                })`,
            }}
          >
            {Slides.ourProducts.map((slide, i) => (
              <React.Fragment key={i}>{slide}</React.Fragment>
            ))}
          </div>
          {viewport === 'mobile' && (
            <div className="slider-controls">
              <a
                onClick={() => prevSlide("ourProducts")}
                disabled={currentSlide === 0}
              >
                <CarouselLeft />
              </a>
              <a
                onClick={() => nextSlide("ourProducts")}
                disabled={currentSlide === Slides.ourProducts.length - 1}
              >
                <CarouselRight />
              </a>
            </div>
          )}
        </div>
      </section>
    </VisibilitySensor>
  )
}

export default OurProducts
