import React, { useState, useRef, useEffect } from "react"
import SmallCarousel from "../components/small-carousel"
import Arrow from "../assets/icons/arrow-right.inline.svg"
import HeroCachepot from "../components/images/hero-cachepot"
import Showcase from "../components/showcase"
import VisibilitySensor from "react-visibility-sensor"
import { Slides } from "../data/Slides"

const Cachepot = ({ viewport }) => {
  const [visible, setVisible] = useState(false)
  const containerRef = useRef(null)

  const onChangeVisibility = isVisible => {
    setVisible(isVisible)
  }
  return (
    <VisibilitySensor
      partialVisibility
      minTopValue="100"
      onChange={onChangeVisibility}
      active={!visible}
    >
      <section
        className={`cachepot ${
          viewport === 'desktop' ? (visible ? "visible" : "invisible") : ""
          }`}
        id="cachepot"
        ref={containerRef}
      >
        <div className="shadow"></div>
        <div className="image">
          <HeroCachepot />
        </div>
        <div className="body">
          <h2>Кашпо</h2>
          <p>Свежий взгляд на привычный предмет</p>
          <a className="arrow-link" href="#contact">
            Узнать цену
              <Arrow />
          </a>
        </div>
        <div className={`showcase ${viewport === 'mobile' ? "carousel" : ""}`}>
          {viewport === 'mobile' ? (
            <SmallCarousel items={Slides.cachepot} />
          ) : (
              <Showcase items={Slides.cachepot} />
            )}
        </div>
      </section>
    </VisibilitySensor>
  )
}

export default Cachepot
