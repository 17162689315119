import React, { useState } from "react"
import Arrow from "../assets/icons/arrow-right.inline.svg"
import VisibilitySensor from "react-visibility-sensor"
import Check from '../assets/icons/check-icon.inline.svg'
import { unisender, API_KEY, hubspot, fullFormEndpoint } from "../api"

const Contact = ({ showDialog }) => {
  const [visible, setVisible] = useState(false)
  const onChangeVisibility = isVisible => {
    setVisible(isVisible)
  }
  const [loading, setLoading] = useState(false)
  const [submitted, setSubmitted] = useState(false)
  const [valid, setValid] = useState(false)
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    phone: '',
    email: '',
    message: '',
    address: '',
  })
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (submitted) return
    if (formData.address !== '') {
      setSubmitted(true)
      return;
    }
    setLoading(true)
    const hubspotFields = [
      { name: 'email', value: formData.email },
      { name: 'firstname', value: formData.firstName },
      { name: 'lastname', value: formData.lastName },
      { name: 'phonenumber', value: formData.phone },
      { name: 'client_message', value: formData.message },
    ]
    const response = await unisender
      .get(`subscribe?format=json&api_key=${API_KEY}&list_ids=19085497&fields[firstName]=${formData.firstName}&fields[lastName]=${formData.lastName}&fields[phoneNumber]=${formData.phone}&fields[email]=${formData.email}&fields[message]=${formData.message}&double_optin=3`)
    if (response.statusText === 'OK') {
      await hubspot.post(`/${fullFormEndpoint}`, {
        fields: hubspotFields
      })
      setSubmitted(true)
    } else if (!response.statusText) {
      setLoading(false)
      console.error(response)
    }
  }
  const validate = (val) => {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    setValid(re.test(String(val).toLowerCase()));
  }

  const handleChange = e => {
    setFormData(
      {
        ...formData,
        [e.target.name]: e.target.value
      }
    )
    if (e.target.name === 'email') {
      validate(e.target.value)
    }
  }
  const content = submitted ?
    <div className='form-submitted'>
      <h1>Ваш запрос принят!</h1>
      <p>Наши сотрудники свяжутся с Вами в ближайшее время</p>
    </div>
    :
    <form onSubmit={e => handleSubmit(e)} style={{ display: visible ? 'grid' : 'none', visibility: visible ? 'visible' : 'hidden' }}>
      <fieldset>
        <label htmlFor="firstName">Имя</label>
        <input value={formData.firstName} onChange={e => handleChange(e)} required name="firstName" id="firstName" type="text"></input>
      </fieldset>
      <fieldset>
        <label htmlFor="lastName">Фамилия</label>
        <input value={formData.lastName} onChange={e => handleChange(e)} required name="lastName" id="lastName" type="text"></input>
      </fieldset>
      <fieldset>
        <label htmlFor="phone">Телефон</label>
        <input value={formData.phone} onChange={e => handleChange(e)} required name="phone" id="phone" type="phone"></input>
      </fieldset>
      <fieldset>
        <label htmlFor="email">E-mail</label>
        <input value={formData.email} onChange={e => handleChange(e)} required name="email" id="email" type="email" autoCapitalize="off"
          autoCorrect="off"></input>
      </fieldset>
      <fieldset className='important'>
        <label htmlFor="address">Address</label>
        <input value={formData.address} onChange={e => handleChange(e)} name="address" id="address" type="address" autoCapitalize="off" autoComplete='your_address'
          autoCorrect="off"></input>
      </fieldset>
      <fieldset>
        <label htmlFor="message">Сообщение</label>
        <textarea value={formData.message} onChange={e => handleChange(e)} name="message" id="message" rows="10"></textarea>
      </fieldset>
      <p>Отправляя форму, вы соглашаетесь на обработку персональных данных, защищенных <a target="_blank" href="/uploads/confidential_policy.pdf">политикой конфиденциальности</a> и обрабатываемых для выполнения вашей заявки.</p>
      <button disabled={!valid} type="submit">{loading ? <div className="loader"></div> : submitted ? <Check /> : 'Отправить'}</button>
    </form>
  return (
    <VisibilitySensor
      partialVisibility
      minTopValue="100"
      onChange={onChangeVisibility}
      active={!visible}
    >
      <section className="contact">
        <a id="contact"></a>
        <h1 style={{ visibility: submitted ? 'hidden' : 'visible' }}>Свяжитесь с нами</h1>
        {content}
        <div className="cta">
          <h2>Или загрузите полный каталог продуктов</h2>
          <a className="arrow-link" onClick={showDialog}>
            Скачать каталог
          <Arrow />
          </a>
        </div>
        <div className="shadow"></div>
      </section>
    </VisibilitySensor>
  )
}

export default Contact
