import React from "react"
import HeroSlider from "../components/hero-slider"
import Arrow from "../assets/icons/arrow-right.inline.svg"

const Hero = ({ showDialog, viewport }) => {
  const renderLink = viewport !== 'desktop' ? (
    <a className="arrow-link" onClick={showDialog}>
      Скачать каталог
      <Arrow />
    </a>
  ) : (
      <a className="arrow-link" href="#contact">
        Скачать каталог
      <Arrow />
      </a>
    )
  return (
    <section className="hero">
      <div className="description">
        <h1>Световая Мебель</h1>
        <p>Производство и продажа мебели</p>
        {renderLink}
      </div>
      <HeroSlider />
    </section>
  )
}

export default Hero
